import React, { useEffect } from 'react';
import '../styles/AboutMe.css';
import me from '../assets/images/me.png';
import { FaCheck, FaCogs, FaChartLine, FaMobileAlt } from 'react-icons/fa'; // Icons für die Abschnitte
import cv_en from '../assets/Kenan_Bayat_CV_2024_english.pdf';
import cv_de from '../assets/Kenan_Bayat_CV_2024_deutsch.pdf';
import docker from '../assets/images/docker.png';
import html from '../assets/images/html.png';
import css from '../assets/images/css.png';
import dj from '../assets/images/dj.png';

const AboutMe = ({ darkMode, language }) => {
    useEffect(() => {
        const fadeInElements = document.querySelectorAll('.fade-in-element');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    entry.target.classList.remove('visible');
                }
            });
        }, {
            threshold: 0
        });

        fadeInElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            fadeInElements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, [darkMode]);

    return (
        <div className={`about-me-container ${darkMode ? 'dark' : 'light'}`}>
            <div className={`about-me-content ${darkMode ? 'dark' : 'light'}`}>
                <div className='me-container fade-in-element'>
                    <div className="about-me-image">
                        <img src={me} alt="Profile" className="profile-pic" />
                    </div>

                    <div className="about-me-text fade-in-element">
                        <h1 className="name">Kenan Bayat</h1>
                        <p className="role">{language === 'EN' ? 'Data Scientist and Fullstack Developer' : 'Data Scientist und Fullstack-Entwickler'}</p>
                        <p className="location">{language === 'EN' ? 'Based in Hamburg' : 'Sitz in Hamburg'} <i className="fas fa-map-marker-alt"></i></p>
                        <div className="button_container">
                            <button
                                className={`resume_button ${darkMode ? 'dark' : 'light'}`}
                                onClick={() => window.open(language === 'EN' ? cv_en : cv_de, '_blank')}
                            >
                                {language === 'EN' ? 'Resume' : 'Lebenslauf'}
                            </button>
                            <a className="ref_contact" href="#footer">
                                <button className={`contact_ref_button ${darkMode ? 'dark' : 'light'}`}>
                                    {language === 'EN' ? 'Contact' : 'Kontakt'}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="about-me-points">
                    {/* Professionalität und Zuverlässigkeit */}
                    <div className="point fade-in-element">
                        <FaCheck className="point-icon" />
                        <div className="point-text">
                            <h3>{language === 'EN' ? 'Professionalism and Reliability' : 'Kompetenz und Verlässlichkeit'}</h3>
                            <p>{language === 'EN' ? 'Committed to delivering high-quality results on time through a professional and reliable work ethic.' : 'Engagiert, hochwertige Ergebnisse termingerecht durch eine professionelle und zuverlässige Arbeitsweise zu liefern.'}</p>
                        </div>
                    </div>

                    {/* Modernste Technologien und Innovation */}
                    <div className="point fade-in-element">
                        <FaCogs className="point-icon" />
                        <div className="point-text">
                            <h3>{language === 'EN' ? 'Cutting-edge Technologies and Innovation' : 'Modernste Technologien und Innovation'}</h3>
                            <p>{language === 'EN' ? 'Harnessing cutting-edge technologies and innovative approaches to develop sustainable, efficient solutions.' : 'Moderne Technologien und innovative Ansätze nutzen, um nachhaltige und effiziente Lösungen zu entwickeln und anzubieten.'}</p>
                        </div>
                    </div>

                    {/* Datengetriebene Lösungen und Analysen */}
                    <div className="point fade-in-element">
                        <FaChartLine className="point-icon" />
                        <div className="point-text">
                            <h3>{language === 'EN' ? 'Data-driven Solutions and Analysis' : 'Datengetriebene Lösungen und Analysen'}</h3>
                            <p>{language === 'EN' ? 'Leveraging data analysis and machine learning to uncover valuable insights and create tailored solutions.' : 'Datenanalyse und maschinelles Lernen nutzen, um wertvolle Erkenntnisse zu gewinnen und maßgeschneiderte Lösungen zu schaffen.'}</p>
                        </div>
                    </div>

                    {/* Individuelle und benutzerfreundliche Softwarelösungen */}
                    <div className="point fade-in-element">
                        <FaMobileAlt className="point-icon" />
                        <div className={`point-text`}>
                            <h3>{language === 'EN' ? 'Custom and User-friendly Software Solutions' : 'Individuelle und benutzerfreundliche Softwarelösungen'}</h3>
                            <p>{language === 'EN' ? 'Creating user-friendly software tailored to meet the unique needs of each client.' : 'Entwicklung benutzerfreundlicher Software, die speziell auf die individuellen Bedürfnisse jedes Kunden zugeschnitten ist.'}</p>
                        </div>
                    </div>
                </div>

                {/* Tech Stack Section */}
                <div className={`technologies_container ${darkMode ? 'dark' : 'light'} fade-in-element`}>
                    <h2>{language === 'EN' ? 'My Tech Stack' : 'Mein Tech Stack'}</h2>
                    <div className="vertical-line"></div>
                    <div className={`tech_stack ${darkMode ? 'dark' : 'light'}`} id="tech_stack">
                        <ul>
                            <li>
                                <div className="pair">
                                    <a href="https://en.wikipedia.org/wiki/Python_(programming_language)" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=py" alt="Python" />
                                    </a>
                                    <a href="https://en.wikipedia.org/wiki/Java_(programming_language)" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=java" alt="Java" />
                                    </a>
                                </div>
                                <div className="pair">
                                    <a href="https://en.wikipedia.org/wiki/React_(JavaScript_library)" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=react" alt="React" />
                                    </a>
                                    <a href="https://en.wikipedia.org/wiki/Spring_(framework)" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=spring" alt="Spring" />
                                    </a>
                                </div>
                                <div className="pair">
                                    <a href="https://en.wikipedia.org/wiki/PostgreSQL" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=postgres" alt="PostgreSQL" />
                                    </a>
                                    <a href="https://en.wikipedia.org/wiki/Gradle" target="_blank" rel="noopener noreferrer">
                                        <img src="https://skillicons.dev/icons?i=gradle" alt="Gradle" />
                                    </a>
                                </div>
                                <div className="pair">
                                    <a href="https://en.wikipedia.org/wiki/Docker_(software)" target="_blank" rel="noopener noreferrer">
                                        <img src={docker} alt="Docker" />
                                    </a>
                                    <a href="https://en.wikipedia.org/wiki/Django_(web_framework)" target="_blank" rel="noopener noreferrer">
                                        <img src={dj} alt="Django" />
                                    </a>
                                </div>
                                <div className="pair">
                                    <a href="https://en.wikipedia.org/wiki/HTML" target="_blank" rel="noopener noreferrer">
                                        <img src={html} alt="HTML" />
                                    </a>
                                    <a href="https://en.wikipedia.org/wiki/Cascading_Style_Sheets" target="_blank" rel="noopener noreferrer">
                                        <img src={css} alt="CSS" />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMe;
