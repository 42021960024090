import React, { useEffect } from 'react';
import '../styles/Projects.css';
import aviation_background from '../assets/images/aviation_background.jpg';
import coffeeImage from '../assets/images/coffee_background.jpg';
import construction_background from '../assets/images/construction_background.jpg';
import analysis_background from '../assets/images/analysis_background.jpg';
import { CauLogo, LhtLogo, AdessoLogo, TchiboLogo } from './Logos.js'
import sql from '../assets/images/sql.png';
import sap from '../assets/images/sap.png';
import ai from '../assets/images/ai.png';
import docker from '../assets/images/docker.png';
import html from '../assets/images/html.png';
import css from '../assets/images/css.png';

const projectsData = [
    {
        id: 1,
        icon: 'assets/Ai_icon.jpg',
        title: {
            EN: 'Investigating AI Pipelining',
            DE: 'Untersuchung der KI-Pipelining'
        },
        startYear: 2024,
        endYear: 2024,
        description: {
            EN: 'A cutting-edge ETL pipeline was designed, developed, and tested, efficiently processing data using state-of-the-art artificial intelligence and advanced machine learning methods. The insights derived from raw data are visually presented and made available for Retrieval-Augmented Generation, significantly enhancing decision-making and operational efficiency.',
            DE: 'Eine hochmoderne ETL-Pipeline wurde konzipiert, entwickelt und getestet, die Daten effizient mithilfe von state-of-the-art künstlicher Intelligenz und fortschrittlichen Machine-Learning-Methoden verarbeitet. Die aus den Rohdaten gewonnenen Erkenntnisse werden visuell dargestellt und für Retrieval-Augmented Generation zur Verfügung gestellt, was die Entscheidungsfindung und die betriebliche Effizienz erheblich verbessert.'
        },
        cooperation: {
            EN: 'Lufthansa Technik',
            DE: 'Lufthansa Technik'
        },
        background_image: aviation_background,
        cooperation_logo: LhtLogo,
        logo_name: 'lht-logo',
        technologies: [
            ai,
            'https://skillicons.dev/icons?i=python',
            'https://skillicons.dev/icons?i=elasticsearch',
            'https://skillicons.dev/icons?i=gitlab',
            'https://skillicons.dev/icons?i=tensorflow',
            docker,
        ],
        footer: {
            EN: 'Details cannot be published.',
            DE: 'Details können nicht veröffentlicht werden.'
        },
    },
    {
        id: 2,
        icon: 'assets/sap_tchibo.png',
        title: {
            EN: 'Enterprise SAP Integration for Retail',
            DE: 'Enterprise-SAP-Integration für den Einzelhandel'
        },
        startYear: 2022,
        endYear: 2023,
        description: {
            EN: 'Developed comprehensive services on the SAP CAR OPP/PMR platform and created RESTful APIs for secure data transmission. By optimizing data pipelines and modeling database structures, the reliability and efficiency of retail services were significantly improved, ensuring timely access to critical insights for stakeholders and consistent real-time data availability.',
            DE: 'Entwicklung umfassender Dienste auf der SAP CAR OPP/PMR-Plattform und Erstellung von RESTful APIs für sichere Datenübertragung. Durch die Optimierung von Datenpipelines und die Modellierung von Datenstrukturen wurde die Zuverlässigkeit und Effizienz der Einzelhandelsdienste erheblich verbessert, was einen zeitnahen Zugang zu kritischen Erkenntnissen für die Stakeholder und eine konsistente Datenverfügbarkeit in Echtzeit gewährleistet.'
        },
        cooperation: {
            EN: 'Tchibo',
            DE: 'Tchibo'
        },
        background_image: coffeeImage,
        cooperation_logo: TchiboLogo,
        logo_name: 'tchibo-logo',
        technologies: [
            sql,
            sap,
            'https://skillicons.dev/icons?i=eclipse',
        ],
        footer: {
            EN: 'Details cannot be published.',
            DE: 'Details können nicht veröffentlicht werden.'
        },
    },
    {
        id: 3,
        icon: 'assets/cash.png',
        title: {
            EN: 'CASH',
            DE: 'CASH'
        },
        startYear: 2022,
        endYear: 2022,
        description: {
            EN: 'Development and implementation of the CASH algorithm for identifying clusters in high-dimensional data. By recognizing patterns in arbitrarily oriented subspaces, the algorithm enables the analysis of market trends and uncovers hidden correlations. This innovative solution helps organizations gain valuable insights into complex datasets and efficiently identify trends.',
            DE: 'Entwicklung und Implementierung des CASH-Algorithmus zur Identifizierung von Clustern in hochdimensionalen Daten. Durch die Fähigkeit, Muster in beliebig orientierten Unterräumen zu erkennen, ermöglicht der Algorithmus die Analyse von Markttrends und das Aufdecken versteckter Korrelationen. Diese innovative Lösung unterstützt Unternehmen dabei, wertvolle Einblicke in komplexe Datensätze zu gewinnen und Trends effizient zu erkennen.'
        },
        cooperation: {
            EN: 'CAU',
            DE: 'CAU'
        },
        background_image: analysis_background,
        cooperation_logo: CauLogo,
        logo_name: 'cau-logo',
        technologies: [
            'https://skillicons.dev/icons?i=python',
            'https://skillicons.dev/icons?i=postgresql',
            'https://skillicons.dev/icons?i=vscode',
            'https://skillicons.dev/icons?i=gitlab',
            'https://skillicons.dev/icons?i=matlab',
            docker
        ],
        footer: (
            <a href="https://github.com/KenanBayat/CASH" className="project_link" target="_blank" rel="noopener noreferrer">
                Github Repo
                <img className="github_logo" src="https://skillicons.dev/icons?i=github" alt="Github Logo" />
            </a>
        ),
    },
    {
        id: 4,
        icon: 'assets/contracktor_logo.jpg',
        title: {
            EN: 'Contracktor Web Application',
            DE: 'Contracktor-Webanwendung'
        },
        startYear: 2021,
        endYear: 2021,
        description: {
            EN: 'As a product owner, I led the development of a web application for the construction industry that streamlines contract management and project progress tracking. The software includes an account management system with role-based access, ensuring data security while providing immediate insights. By integrating RESTful APIs and optimizing data structures, this solution reduces bureaucratic efforts and enhances efficiency for organizations.',
            DE: 'Als Product Owner leitete ich die Entwicklung einer Webanwendung für die Bauindustrie, die das Vertragsmanagement und die Nachverfolgung des Baufortschritts optimiert. Die Software umfasst ein Benutzerverwaltungssystem mit rollenbasiertem Zugriff, das die Datensicherheit gewährleistet und gleichzeitig sofortige Einblicke bietet. Durch die Integration von RESTful APIs und die Optimierung der Datenstrukturen reduziert diese Lösung den bürokratischen Aufwand und erhöht die Effizienz für Organisationen.'
        },
        cooperation: {
            EN: 'Adesso',
            DE: 'Adesso'
        },
        background_image: construction_background,
        cooperation_logo: AdessoLogo,
        logo_name: 'adesso-logo',
        technologies: [
            'https://skillicons.dev/icons?i=java',
            'https://skillicons.dev/icons?i=spring',
            'https://skillicons.dev/icons?i=gradle',
            docker,
            html,
            css,
        ],
        footer: (
            <a href="https://github.com/KenanBayat/Contracktor" className="project_link" target="_blank" rel="noopener noreferrer">
                Github Repo
                <img className="github_logo" src="https://skillicons.dev/icons?i=github" alt="Github Logo" />
            </a>
        ),
    },
];

const renderFooter = (footer, language) => {
    if (typeof footer === 'object' && footer !== null) {
        if (footer.EN && footer.DE) {
            return language === 'EN' ? footer.EN : footer.DE;
        }
        return footer;
    }
    return footer;
};

const Projects = ({ darkMode, language }) => {
    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    entry.target.classList.remove('visible');
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        const fadeInElements = document.querySelectorAll('.fade-in-element');
        fadeInElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            fadeInElements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, []);

    return (
        <div className='projects-wrapper'>
            <div id='projects_anchor'></div>
            <h1 className='project-header fade-in-element'>{language === 'EN' ? 'My Projects' : 'Meine Projekte'}</h1>
            <div className={`projects-container ${darkMode ? 'dark' : 'light'}`}>

                {projectsData.length === 0 ? (
                    <p>{language === 'EN' ? 'No projects available' : 'Keine Projekte verfügbar'}</p>
                ) : (
                    projectsData.map((project) => (
                        <div key={project.id} className={`project-card ${darkMode ? 'dark' : 'light'} fade-in-element`}>
                            <div className="project-image" style={{ backgroundImage: `url(${project.background_image})` }}></div>
                            <div className="project-info">
                                <div className="project-content">
                                    <h2 className="project-title">{language === 'EN' ? project.title.EN : project.title.DE}</h2>
                                    <p className="project-year">{project.startYear} - {project.endYear}</p>
                                    <p className="project-description">{language === 'EN' ? project.description.EN : project.description.DE}</p>
                                    <p className="project-cooperation">
                                        {language === 'EN' ? 'In cooperation with:' : 'In Zusammenarbeit mit:'}
                                        <project.cooperation_logo className="cooperation-logo" name={projectsData.logo_name}></project.cooperation_logo>
                                    </p>
                                    <div className="project-technologies">
                                        <p>{language === 'EN' ? 'Used Technologies:' : 'Verwendete Technologien:'}</p>
                                        <div className='technologies' style={{ height: '100%' }}>
                                            {project.technologies.map((tech, index) => (
                                                <img key={index} src={tech} alt="Technology Icon" className="tech-icon" />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="project-footer">
                                        {renderFooter(project.footer, language)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Projects;