import React from 'react';
import '../styles/Imprint.css';

const Imprint = ({ language }) => {
    return (
        <div className='imprint'>
            <h1>{language === 'EN' ? 'Imprint' : 'Impressum'}</h1>
            <p>{language === 'EN' ? 'Details as per § 5 TMG' : 'Angaben gemäß § 5 TMG'}</p>
            <p>
                Kenan Bayat <br />
                Rotbuchenkamp 21 <br />
                25421 Pinneberg <br />
            </p>
            <p>
                <strong>{language === 'EN' ? 'Represented by:' : 'Vertreten durch:'}</strong> <br />
                Kenan Bayat <br />
            </p>
            <p>
                <strong>{language === 'EN' ? 'Contact:' : 'Kontakt:'}</strong> <br />
                E-Mail: <a href='mailto:kenan.bayat.info@gmail.com' className="imprint_mail">kenan.bayat.info@gmail.com</a>
            </p>
            <p className='disclaimers'>
                <strong>{language === 'EN' ? 'Disclaimer:' : 'Haftungsausschluss:'}</strong> <br />
                <strong>{language === 'EN' ? 'Liability for Content' : 'Haftung für Inhalte'}</strong><br />
                {language === 'EN'
                    ? 'The content of our pages has been created with the utmost care. However, we cannot guarantee the correctness, completeness, and timeliness of the content. As a service provider, we are responsible for our own content on these pages according to general laws. According to §§ 8 to 10 TMG, we are not obligated to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Responsibilities to remove or block the use of information under general laws remain unaffected. A liability in this regard is, however, only possible from the moment of knowledge of a specific infringement. Upon becoming aware of any such violations, we will remove this content immediately.'
                    : 'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Dennoch können wir keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte übernehmen. Als Dienstanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte auf diesen Seiten nach allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Die Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.'}
                <br />
                <strong>{language === 'EN' ? 'Liability for Links' : 'Haftung für Links'}</strong><br />
                {language === 'EN'
                    ? 'Our offer contains links to external websites of third parties, on whose content we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking. However, a permanent control of the content of the linked pages is not reasonable without concrete evidence of an infringement. Upon becoming aware of any legal violations, we will remove such links immediately.'
                    : 'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Daher können wir für diese fremden Inhalte keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente Kontrolle der Inhalte der verlinkten Seiten ist ohne konkrete Anhaltspunkte einer Rechtsverletzung jedoch nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.'}
                <br />
                <strong>{language === 'EN' ? 'Copyright' : 'Urheberrecht'}</strong><br />
                {language === 'EN'
                    ? 'The content and works created by the page operators on these pages are subject to German copyright law. The duplication, editing, distribution, and any form of exploitation outside the limits of copyright require the written consent of the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial use. As far as the content on this page was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. If you still become aware of a copyright infringement, we ask for a corresponding note. Upon becoming aware of any legal violations, we will remove such content immediately.'
                    : 'Die Inhalte und Werke, die von den Seitenbetreibern auf diesen Seiten erstellt wurden, unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors oder Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter respektiert. Insbesondere werden die Inhalte Dritter als solche gekennzeichnet. Wenn Sie trotzdem auf einen Urheberrechtsverstoß aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.'}
                <br />
                <strong>{language === 'EN' ? 'Data Protection' : 'Datenschutz'}</strong><br />
                {language === 'EN'
                    ? 'The use of our website is generally possible without providing personal data. Insofar as personal data (for example, name, address, or email addresses) are collected on our pages, this is always done on a voluntary basis, as far as possible. This data will not be passed on to third parties without your explicit consent. We would like to point out that data transmission over the Internet (e.g., when communicating by email) can have security gaps. A complete protection of the data against access by third parties is not possible. The use of contact data published in the context of the imprint obligation by third parties for the purpose of sending unsolicited advertising and information materials is hereby expressly opposed. The operators of the pages reserve the right to take legal action in case of the unsolicited sending of advertising information, for example, through spam emails.'
                    : 'Die Nutzung unserer Webseite ist grundsätzlich ohne Angabe personenbezogener Daten möglich. Sofern auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein vollständiger Schutz der Daten vor dem Zugriff Dritter ist nicht möglich. Der Nutzung von Kontaktdaten, die im Rahmen der Impressumspflicht veröffentlicht wurden, durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.'}
            </p>
        </div>
    );
}

export default Imprint;
