import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Imprint from './components/Imprint';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/global.css';
import WelcomeAnimation from './components/WelcomeAnimation';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [language, setLanguage] = useState('EN');
  const [animationComplete, setAnimationComplete] = useState(false);

  React.useEffect(() => {
    document.body.className = darkMode ? 'dark' : 'light';
  }, [darkMode]);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
    document.body.classList.remove('no-scroll');
    window.scrollTo(0, document.getElementById('about_me_anchor').offsetTop);
  };

  return (
    <Router>
      <div className={darkMode ? 'dark' : 'light'}>
        <Header darkMode={darkMode} setDarkMode={setDarkMode} language={language} setLanguage={setLanguage} />

        {!animationComplete && <WelcomeAnimation onAnimationComplete={handleAnimationComplete} />}

        <Routes>
          <Route path="/" element={
            <>
              <div id='about_me_anchor'></div>
              <AboutMe darkMode={darkMode} language={language} />
              <Projects darkMode={darkMode} language={language} />
              <Contact darkMode={darkMode} language={language} />
            </>
          } />
          <Route path="/imprint" element={<Imprint language={language} />} />
        </Routes>

        <div id='contact_anchor'></div>
        <Footer darkMode={darkMode} language={language} />
      </div>
    </Router>
  );
}

export default App;