import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import logo_light from '../assets/images/logo_light.jpg';
import logo_dark from '../assets/images/logo_dark.jpg';
import cv_en from '../assets/Kenan_Bayat_CV_2024_english.pdf';
import cv_de from '../assets/Kenan_Bayat_CV_2024_deutsch.pdf';
import { useLocation, Link } from 'react-router-dom'; // Import Link und useLocation

function Header({ darkMode, setDarkMode, language, setLanguage }) {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation(); // Aktuelle Route abfragen

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
            disableScroll();
        } else {
            enableScroll();
        }
    };

    const handleMenuItemClick = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);

        if (!targetElement) {
            console.error(`Element mit ID "${targetId}" wurde nicht gefunden.`);
            return;
        }

        const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
        const offsetHeight = window.innerHeight / 2 - targetElement.offsetHeight / 2;

        window.scrollTo({
            top: offsetTop - offsetHeight,
            behavior: 'smooth',
        });

        setMenuOpen(false);
        enableScroll();
    };

    useEffect(() => {
        if (!menuOpen) {
            enableScroll();
        }
    }, [menuOpen]);

    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
        document.body.style.overflow = '';
    };

    return (
        <header className={`header ${darkMode ? 'dark' : 'light'}`}>
            <LeftHeader darkMode={darkMode} className="left_header" />
            <div className="right_header">
                <RightHeader
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                    language={language}
                    setLanguage={setLanguage}
                    toggleMenu={toggleMenu}
                    menuOpen={menuOpen}
                />
            </div>

            {location.pathname !== '/imprint' && (
                <>
                    <div className={`menu_overlay ${darkMode ? 'dark' : ''} ${menuOpen ? 'show' : ''}`} />
                    <MenueView
                        menuOpen={menuOpen}
                        handleMenuItemClick={handleMenuItemClick}
                        darkMode={darkMode}
                        language={language}
                    />
                </>
            )}
        </header>
    );
}

function LeftHeader({ darkMode }) {
    return (
        <div className="logo-container">
            <img src={darkMode ? logo_dark : logo_light} alt="Logo" className="logo" />
            <div id="name">
                <span className="nameblock">Kenan</span>
                <span className="nameblock">Bayat</span>
            </div>
        </div>
    )
}

function RightHeader({ darkMode, setDarkMode, language, setLanguage, toggleMenu, menuOpen }) {
    const location = useLocation();

    return (
        <div className="controls">
            <LightDarkButton darkMode={darkMode} setDarkMode={setDarkMode} />
            <LanguageButton language={language} setLanguage={setLanguage} darkMode={darkMode} />
            {location.pathname !== '/imprint' && (
                <MenueBurger toggleMenu={toggleMenu} menuOpen={menuOpen} darkMode={darkMode} language={language} />
            )}
            {location.pathname === '/imprint' && (
                <Link to="/" className="back-button">
                    {language === 'EN' ? 'Home' : 'Zurück'}
                </Link>
            )}
        </div>
    );
}
function LightDarkButton({ darkMode, setDarkMode }) {
    return (
        <div className={`toggle-container ${darkMode ? 'dark' : 'light'}`}>
            <button
                type="button"
                className={`icon-button-light ${darkMode ? 'dark' : 'light'}`}
                onClick={() => setDarkMode(false)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none"></rect>
                    <circle cx="128" cy="128" r="60" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></circle>
                    <line x1="128" y1="36" x2="128" y2="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="62.9" y1="62.9" x2="48.8" y2="48.8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="36" y1="128" x2="16" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="62.9" y1="193.1" x2="48.8" y2="207.2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="128" y1="220" x2="128" y2="240" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="193.1" y1="193.1" x2="207.2" y2="207.2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="220" y1="128" x2="240" y2="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="193.1" y1="62.9" x2="207.2" y2="48.8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                </svg>
            </button>
            <button
                type="button"
                className={`icon-button-dark ${darkMode ? 'dark' : 'light'}`}
                onClick={() => setDarkMode(true)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none"></rect>
                    <line x1="216" y1="112" x2="216" y2="64" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="240" y1="88" x2="192" y2="88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="168" y1="24" x2="168" y2="56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <line x1="184" y1="40" x2="152" y2="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></line>
                    <path d="M216.7,152.6A91.9,91.9,0,0,1,103.4,39.3h0A92,92,0,1,0,216.7,152.6Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"></path>
                </svg>
            </button>
            <div className={`toggle-ball ${darkMode ? 'dark' : 'light'}`}></div>
        </div>
    )
}

function LanguageButton({ language, setLanguage, darkMode }) {
    return (
        <div className={`toggle-container ${darkMode ? 'dark' : 'light'}`}>
            <span
                className={`en-button ${language === 'EN' ? 'active' : ''}`}
                onClick={() => setLanguage('EN')}
            >
                EN
            </span>
            <span
                className={`de-button ${language === 'DE' ? 'active' : ''}`}
                onClick={() => setLanguage('DE')}
            >
                DE
            </span>
            <div className={`toggle-ball-language ${darkMode ? 'dark' : 'light'} ${language === 'EN' ? 'en' : 'de'}`}></div>
        </div>
    );
}


function MenueBurger({ toggleMenu, menuOpen, darkMode, language }) {
    return (
        <div className={`menu_button ${darkMode ? 'dark' : 'light'}`} onClick={toggleMenu}>
            <span className="menu_text">{menuOpen ? (language === 'EN' ? 'Close' : 'Zurück') : (language === 'EN' ? 'Menu' : 'Menü')}</span>
            <a className={`menu_toggle ${menuOpen ? 'active-menu' : ''} ${darkMode ? 'dark' : 'light'}`} href="#main-nav">
                <i></i>
            </a>
        </div>
    );
}

function MenueView({ menuOpen, handleMenuItemClick, darkMode, language }) {
    return (
        <div id="menu_view" className={`${menuOpen ? 'show' : ''} ${darkMode ? 'dark' : 'light'}`}>
            <ul>
                <li>
                    <a href="#about_me_anchor" onClick={(e) => handleMenuItemClick(e, 'about_me_anchor')}>
                        {language === 'EN' ? 'About Me' : 'Über Mich'}
                    </a>
                </li>
                <li>
                    <a href="#projects_anchor" onClick={(e) => handleMenuItemClick(e, 'projects_anchor')}>
                        {language === 'EN' ? 'Projects' : 'Projekte'}
                    </a>
                </li>
                <li id="resume-link">
                    <a
                        href={language === 'EN' ? cv_en : cv_de}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {language === 'EN' ? 'Resume' : 'Lebenslauf'}
                    </a>
                </li>
                <li>
                    <a href="#contact_anchor" onClick={(e) => handleMenuItemClick(e, 'contact_anchor')}>
                        {language === 'EN' ? 'Contact' : 'Kontakt'}
                    </a>
                </li>
                <li>
                    <a href="#footer" onClick={(e) => handleMenuItemClick(e, 'footer')}>
                        {language === 'EN' ? 'Socials' : 'Socials'}
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Header;
