import '../styles/Contact.css';
import contact_image from '../assets/images/me_contact.png';
import { useEffect } from 'react';

function Contact({ darkMode, language }) {
    useEffect(() => {
        const fadeInElements = document.querySelectorAll('.fade-in-element');
        const buttons = document.querySelectorAll('.contact_button');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    entry.target.classList.remove('visible');
                }
            });
        }, {
            threshold: 0
        });

        fadeInElements.forEach(element => {
            observer.observe(element);
        });
        buttons.forEach(button => {
            observer.observe(button);
        });

        return () => {
            fadeInElements.forEach(element => {
                observer.unobserve(element);
            });
            buttons.forEach(button => {
                observer.unobserve(button);
            });
        };
    }, [darkMode]);

    return (
        <div className={`contact_section ${darkMode ? 'dark' : 'light'}`}>
            <div id="motto_container" className="fade-in-element">
                <img className="profile_image fade-in-element" src={contact_image} alt="Profile" />
                <div className={`motto fade-in-element ${darkMode ? 'dark' : 'light'}`}>
                    <h3>{language === 'EN' ? 'If there is no solution, then there is no problem.' : 'Wenn es keine Lösung gibt, gibt es kein Problem.'}</h3>
                    <p>{language === 'EN' ? 'Need a solution for your problem? Let\'s work together.' : 'Brauchen Sie eine Lösung für Ihr Problem?'}</p>
                </div>
            </div>
            <hr className="divider fade-in-element" />
            <div className={`contact_buttons ${darkMode ? 'dark' : 'light'}`}>
                <h3 className='fade-in-element'>{language === 'EN' ? 'Feel free to reach out' : 'Melden Sie sich gerne bei mir'}</h3>
                <div className="button_wrapper">
                    <a className={`contact_button email ${darkMode ? 'dark' : 'light'}`} href="mailto:kenan.bayat.info@gmail.com">
                        <i className="fas fa-envelope"></i> {language === 'EN' ? 'kenan.bayat.info@gmail.com' : 'kenan.bayat.info@gmail.com'}
                    </a>
                    <a className={`contact_button phone ${darkMode ? 'dark' : 'light'}`} href="tel:+49 015679 419033">
                        <i className="fas fa-phone"></i> {language === 'EN' ? '+49 015679 419033' : '+49 015679 419033'}
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
