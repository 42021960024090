import React, { useEffect } from 'react';
import anime from 'animejs';
import '../styles/WelcomeAnimation.css';

const WelcomeAnimation = ({ onAnimationComplete }) => {
    useEffect(() => {
        const navigationEntries = performance.getEntriesByType("navigation");
        if (navigationEntries.length > 0 && navigationEntries[0].type === "reload") {
            localStorage.removeItem('welcomeAnimationPlayed');
        }

        if (!localStorage.getItem('welcomeAnimationPlayed')) {
            document.body.classList.add('no-scroll');
            window.scrollTo(0, 0);

            const paths = document.querySelectorAll('.letters path');
            paths.forEach((path) => {
                const length = path.getTotalLength();
                path.style.strokeDasharray = length;
                path.style.strokeDashoffset = length;
            });

            anime({
                targets: '.letters path',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeOutQuad',
                duration: 1000,
                delay: (el, i) => i * 250,
                direction: 'alternate',
                loop: false,
                complete: () => {
                    localStorage.setItem('welcomeAnimationPlayed', 'true');
                    document.body.classList.remove('no-scroll');  // Klasse nach der Animation entfernen
                    onAnimationComplete();
                }
            });
        } else {
            onAnimationComplete();
        }
    }, [onAnimationComplete]);

    return (
        <div id="welcome_animation">
            <svg width="100vw" height="100vh" viewBox="0 0 400 200">
                <g fill="none" fillRule="evenodd" stroke="white" strokeWidth="0.5" className="letters">
                    <path
                        d="m 135.09218,168.03717 -12.15625,-45.8125 h 6.21875 l 6.96875,30.03125 q 1.125,4.71875 1.9375,9.375 1.75,-7.34375 2.0625,-8.46875 l 8.71875,-30.9375 h 7.3125 l 6.5625,23.1875 q 2.46875,8.625 3.5625,16.21875 0.875,-4.34375 2.28125,-9.96875 l 7.1875,-29.4375 h 6.09375 l -12.5625,45.8125 h -5.84375 l -9.65625,-34.90625 q -1.21875,-4.375 -1.4375,-5.375 -0.71875,3.15625 -1.34375,5.375 l -9.71875,34.90625 z"
                        style={{ strokeDashoffset: '315.317px' }} strokeDasharray="316.85528564453125"></path>
                    <path
                        d="m 208.34218,157.34967 5.8125,0.71875 q -1.375,5.09375 -5.09375,7.90625 -3.71875,2.8125 -9.5,2.8125 -7.28125,0 -11.5625,-4.46875 -4.25,-4.5 -4.25,-12.59375 0,-8.375 4.3125,-13 4.3125,-4.625 11.1875,-4.625 6.65625,0 10.875,4.53125 4.21875,4.53125 4.21875,12.75 0,0.5 -0.0312,1.5 h -24.75 q 0.3125,5.46875 3.09375,8.375 2.78125,2.90625 6.9375,2.90625 3.09375,0 5.28125,-1.625 2.1875,-1.625 3.46875,-5.1875 z m -18.46875,-9.09375 h 18.53125 q -0.375,-4.1875 -2.125,-6.28125 -2.6875,-3.25 -6.96875,-3.25 -3.875,0 -6.53125,2.59375 -2.625,2.59375 -2.90625,6.9375 z"
                        style={{ strokeDashoffset: '150px' }} strokeDasharray="150"></path>
                    <path d="m 221.09218,168.03717 v -45.8125 h 5.625 v 45.8125 z" style={{ strokeDashoffset: '441.174px' }}
                        strokeDasharray="441.1739501953125"></path>
                    <path
                        d="m 257.09218,155.88092 5.53125,0.71875 q -0.90625,5.71875 -4.65625,8.96875 -3.71875,3.21875 -9.15625,3.21875 -6.8125,0 -10.96875,-4.4375 -4.125,-4.46875 -4.125,-12.78125 0,-5.375 1.78125,-9.40625 1.78125,-4.03125 5.40625,-6.03125 3.65625,-2.03125 7.9375,-2.03125 5.40625,0 8.84375,2.75 3.4375,2.71875 4.40625,7.75 l -5.46875,0.84375 q -0.78125,-3.34375 -2.78125,-5.03125 -1.96875,-1.6875 -4.78125,-1.6875 -4.25,0 -6.90625,3.0625 -2.65625,3.03125 -2.65625,9.625 0,6.6875 2.5625,9.71875 2.5625,3.03125 6.6875,3.03125 3.3125,0 5.53125,-2.03125 2.21875,-2.03125 2.8125,-6.25 z"
                        style={{ strokeDashoffset: '338.305px' }} strokeDasharray="338.3053894042969"></path>
                    <path
                        d="m 265.34218,151.44342 q 0,-9.21875 5.125,-13.65625 4.28125,-3.6875 10.4375,-3.6875 6.84375,0 11.1875,4.5 4.34375,4.46875 4.34375,12.375 0,6.40625 -1.9375,10.09375 -1.90625,3.65625 -5.59375,5.6875 -3.65625,2.03125 -8,2.03125 -6.96875,0 -11.28125,-4.46875 -4.28125,-4.46875 -4.28125,-12.875 z m 5.78125,0 q 0,6.375 2.78125,9.5625 2.78125,3.15625 7,3.15625 4.1875,0 6.96875,-3.1875 2.78125,-3.1875 2.78125,-9.71875 0,-6.15625 -2.8125,-9.3125 -2.78125,-3.1875 -6.9375,-3.1875 -4.21875,0 -7,3.15625 -2.78125,3.15625 -2.78125,9.53125 z"
                        style={{ strokeDashoffset: '406.87px' }} strokeDasharray="406.8699035644531"></path>
                    <path
                        d="m 303.02968,168.03717 v -33.1875 h 5.03125 v 4.65625 q 1.5625,-2.4375 4.15625,-3.90625 2.59375,-1.5 5.90625,-1.5 3.6875,0 6.03125,1.53125 2.375,1.53125 3.34375,4.28125 3.9375,-5.8125 10.25,-5.8125 4.9375,0 7.59375,2.75 2.65625,2.71875 2.65625,8.40625 v 22.78125 h -5.59375 v -20.90625 q 0,-3.375 -0.5625,-4.84375 -0.53125,-1.5 -1.96875,-2.40625 -1.4375,-0.90625 -3.375,-0.90625 -3.5,0 -5.8125,2.34375 -2.3125,2.3125 -2.3125,7.4375 v 19.28125 h -5.625 v -21.5625 q 0,-3.75 -1.375,-5.625 -1.375,-1.875 -4.5,-1.875 -2.375,0 -4.40625,1.25 -2,1.25 -2.90625,3.65625 -0.90625,2.40625 -0.90625,6.9375 v 17.21875 z"
                        style={{ strokeDashoffset: '301.856px' }} strokeDasharray="301.8561706542969"></path>
                    <path
                        d="m 379.06093,157.34967 5.8125,0.71875 q -1.375,5.09375 -5.09375,7.90625 -3.71875,2.8125 -9.5,2.8125 -7.28125,0 -11.5625,-4.46875 -4.25,-4.5 -4.25,-12.59375 0,-8.375 4.3125,-13 4.3125,-4.625 11.1875,-4.625 6.65625,0 10.875,4.53125 4.21875,4.53125 4.21875,12.75 0,0.5 -0.0312,1.5 h -24.75 q 0.3125,5.46875 3.09375,8.375 2.78125,2.90625 6.9375,2.90625 3.09375,0 5.28125,-1.625 2.1875,-1.625 3.46875,-5.1875 z m -18.46875,-9.09375 h 18.53125 q -0.375,-4.1875 -2.125,-6.28125 -2.6875,-3.25 -6.96875,-3.25 -3.875,0 -6.53125,2.59375 -2.625,2.59375 -2.90625,6.9375 z"
                        strokeDasharray="47.128875732421875"></path>
                </g>
            </svg>
        </div>
    );
};

export default WelcomeAnimation;
