import '../styles/Footer.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer({ language }) {
    const [time, setTime] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const options = {
                hour: '2-digit',
                minute: '2-digit',
                timeZoneName: 'short',
            };
            const formattedTime = now.toLocaleTimeString(language === 'EN' ? 'en-US' : 'de-DE', options);
            setTime(formattedTime);
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000);

        return () => clearInterval(intervalId);
    }, [language]);

    useEffect(() => {
        const fadeInElements = document.querySelectorAll('.fade-in-element');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    entry.target.classList.remove('visible');
                }
            });
        }, {
            threshold: 0
        });

        fadeInElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            fadeInElements.forEach(element => {
                observer.unobserve(element);
            });
        };
    }, []);

    return (
        <div id="footer">
            <div id="footer_content">
                <div id="footer_left" className="fade-in-element">
                    <div className="version">
                        <p className="footer-title">{language === 'EN' ? 'VERSION' : 'VERSION'}</p>
                        <p className="footer_value">{language === 'EN' ? '2024 © Edition' : '2024 © Ausgabe'}</p>
                    </div>
                    <div className="local_time">
                        <p className="footer-title">{language === 'EN' ? 'LOCAL TIME' : 'ORTSZEIT'}</p>
                        <p className="footer_value" id="time">{time}</p>
                    </div>
                </div>
                <div id="copyright" className="fade-in-element">
                    <p>{language === 'EN' ? '© Code by Kenan Bayat' : '© Code von Kenan Bayat'}</p>
                    <Link to="/imprint" className="imprint_ref">
                        {language === 'EN' ? 'Imprint' : 'Impressum'}
                    </Link>
                </div>
                <div id="footer_right" className="fade-in-element">
                    <div id="social_container">
                        <p className="footer-title">{language === 'EN' ? 'SOCIALS' : 'SOZIALE NETZWERKE'}</p>
                        <ul id="social_links">
                            <li>
                                <a className="footer_value" href="https://www.linkedin.com/in/kenan-bayat-92ab9b232/" target="_blank" rel="noopener noreferrer">
                                    LinkedIn
                                </a>
                            </li>
                            <li>
                                <a className="footer_value" href="https://www.xing.com/profile/Kenan_Bayat019390" target="_blank" rel="noopener noreferrer">
                                    XING
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
